import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import SubjectForm from "./SubjectForm";

export default function SubjectModal({subject, open, onClose}) {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{subject ? 'Alterar' : 'Adicionar'} Assunto</DialogTitle>
                <SubjectForm subject={subject} onClose={onClose}></SubjectForm>
            </ModalDialog>
        </Modal>
    );
}
