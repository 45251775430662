import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {Link as RouterLink, useParams, useSearchParams} from "react-router-dom";
import * as React from "react";
import CourseRegistrationStepper from "./CourseRegistrationStepper";

export default function CourseCreatePage() {
    const [searchParams] = useSearchParams();

    let {id} = useParams();
    id = isNaN(Number(id)) ? undefined: id;
    const type = searchParams.get('type');

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        component={RouterLink} to="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to="/registration/lecture"
                        fontSize={12}
                        fontWeight={500}
                    >
                        Cadastro de Cursos
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Adicionar Curso{type !== 'CURSO_LIVRE' ? '(s)' : '' }
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: {xs: 'column', sm: 'row'},
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Adicionar Curso
                </Typography>
            </Box>
            <CourseRegistrationStepper id={id}/>
        </>
    );
}
