import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useDomain({domain, name='', page = 0, size = 1000, sort = 'name'}) {
    if (!domain) {
        throw new Error("domain required");
    }
    const {data, error, isLoading} = useSWR(`/${domain}?name=${name}&page=${page}&sort=${sort}&size=${size}`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}
