import {FormHelperText, Grid} from "@mui/joy";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import DomainSelect from "../../../../components/domain/DomainSelect";
import * as React from "react";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import ErrorAlert from "../../../../components/ErrorAlert";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import SubjectSelect from "../../../../components/domain/subject/SubjectSelect";
import { useSWRConfig } from "swr"

export default function LectureBasicInfoStep({lecture, courseId, moduleId, sectionId = ''}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const { mutate } = useSWRConfig()
    const {handleSubmit, control, watch, setValue, formState: {errors}} = useForm({mode: 'all'});
    const navigate = useNavigate();

    const discipline = watch('discipline');

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        (lecture ? axios.put(`lecture/${lecture.id}`, data) : axios.post(`lecture?sectionId=${sectionId}`, data))
            .then(response => {
                mutate((key) => typeof key === 'string' && (key.startsWith(`/lecture`) || key.startsWith(`/module/${moduleId}/section`)),  undefined,
                    { revalidate: true })
                    .then(() => navigate(`/registration/lecture/${response.data.id}/?step=1&courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}`));
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    }

    const handleBack = () => {
        if(courseId && moduleId && sectionId) {
            navigate(`/registration/course/edit/${courseId}/module/${moduleId}?sectionId=${sectionId}`)
        } else {
            navigate("/registration/lecture")
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Dados da Aula</Typography>
                </Box>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Stack direction="row">
                            <Controller
                                name="name"
                                defaultValue={lecture?.name || ""}
                                control={control}
                                rules={{required: 'Campo obrigatório'}}
                                helperText={errors.name?.message}
                                render={({field}) =>
                                    <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.name}>
                                        <FormLabel>Nome</FormLabel>
                                        <Input type="text" {...field}/>
                                        <FormHelperText>{errors.name?.message}</FormHelperText>
                                    </FormControl>
                                }
                            />
                            <Box sx={{height: 34, width: 34, mt: '24px', ml: '8px'}}>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xl={3} lg={4} sm={6} xs={12}>
                        <DomainSelect control={control}
                                      errors={errors}
                                      setValue={setValue}
                                      defaultValue={lecture?.teacher}
                                      domain="teacher"
                                      title="Professor(a)"/>
                    </Grid>
                    <Grid xl={3} lg={4} sm={6} xs={12}>
                        <DomainSelect control={control}
                                      errors={errors}
                                      setValue={setValue}
                                      defaultValue={lecture?.discipline}
                                      mandatory={false}
                                      domain="discipline"
                                      title="Disciplina"/>
                    </Grid>
                    <Grid xl={3} lg={4} sm={6} xs={12}>
                        <SubjectSelect control={control}
                                       errors={errors}
                                       setValue={setValue}
                                       discipline={discipline || lecture?.discipline}
                                       mandatory={false}
                                       defaultValue={lecture?.subjects}
                                       multiple={true}/>
                    </Grid>
                </Grid>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button size="sm" variant="soft" onClick={handleBack}>
                            Voltar
                        </Button>
                        <Button loading={submiting} type="submit" size="sm" variant="solid">
                            Próximo
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    )
}
