import {Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Divider from "@mui/joy/Divider";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function SectionLectureMenu({lecture, onDelete}) {
    const navigate = useNavigate();

    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }}>
                {/*<MenuItem onClick={() => navigate(`${lecture.id}`)}>Editar</MenuItem>*/}
                {/*<Divider />*/}
                <MenuItem onClick={() => onDelete(lecture)} color="danger">Remover</MenuItem>
            </Menu>
        </Dropdown>
    )
}
