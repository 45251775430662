import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useLectures({
                                 name = [],
                                 disciplineId = [],
                                 subjectId = [],
    teacherId = [],
                                 page = 0,
                                 size = 10,
                                 sort = 'name,asc',

                             }) {
    const {
        data,
        error,
        isLoading
    } = useSWR(`/lecture?page=${page}&sort=${sort}&size=${size}`
        + `&teacherId=${teacherId.join("&teacherId=")}`
        + `&disciplineId=${disciplineId.join("&disciplineId=")}`
        + `&subjectId=${subjectId.join("&subjectId=")}`
        + `&name=${name}`
        , fetcher,
        {
            revalidateIfStale: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: true
        });
    return [data, isLoading, error];
}
