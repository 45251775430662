import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {Link as RouterLink, useParams} from "react-router-dom";
import * as React from "react";
import {useCourse} from "../../../../hooks/useCourse";
import CourseEditForm from "./CourseEditForm";
import ErrorAlert from "../../../../components/ErrorAlert";
import {CircularProgress} from "@mui/joy";
import ModuleTable from "./module/ModuleTable";
import Card from "@mui/joy/Card";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/joy/Divider";
import ModuleCreateModal from "./module/ModuleCreateModal";

export default function CourseEditPage() {
    let {id} = useParams();
    id = isNaN(Number(id)) ? undefined : id;
    const [course, loading, error] = useCourse(id);

    if (!course) {
        return undefined;
    }

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        component={RouterLink} to="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to="/registration/course"
                        fontSize={12}
                        fontWeight={500}
                    >
                        Cadastro de Cursos
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Editar Curso
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: {xs: 'column', sm: 'row'},
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Editar Curso
                </Typography>
            </Box>
            {error ? <ErrorAlert error={error}/> : undefined}
            {loading ? (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress/></Box>
            ) : (
                <>
                    <CourseEditForm course={course}/>
                    <Card sx={{mt: 4, maxWidth: 'lg', width: 1, mx: 'auto'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                            <Typography level="title-md">Módulos</Typography>
                            <ModuleCreateModal courseId={course.id} />
                        </Box>
                        <Divider/>
                        <ModuleTable courseId={course.id} defaultModules={course.modules} key={course.modules} />
                    </Card>
                </>
            )}

        </>
    );
}
