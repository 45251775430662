import {CircularProgress, Step, StepButton, StepIndicator, Stepper} from "@mui/joy";
import {Check} from "@mui/icons-material";
import Box from "@mui/joy/Box";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as React from "react";
import ErrorAlert from "../../../../../components/ErrorAlert";
import {useLecture} from "../../../../../hooks/useLecture";
import CourseRegistrationStepperContent from "./CourseRegistrationStepperContent";

const getModuleName = (moduleType, days) => {
    switch (moduleType) {
        case 'PLANEJAMENTO':
            return 'PLANEJAMENTO DE ESTUDOS';
        case 'MENTORIA':
            return 'MENTORIA';
        case 'DISCURSIVA':
            return 'PREPARAÇÃO PARA A DISCURSIVA';
        case 'APROVACAO':
            return 'APROVAÇÃO EM ' + days + ' DIAS';
        case 'EVENTOS':
            return 'EVENTOS ONLINE';
        case 'SIMULADO':
            return 'SIMULADOS';
        case 'DETONANDO':
            return 'DETONANDO A BANCA';
    }
}

const getModule = (moduleType, days) => {
    switch (moduleType) {
        case 'PLANEJAMENTO':
            return {
                name: 'PLANEJAMENTO DE ESTUDOS',
                position: 1,
                sections: [{
                    name: 'PLANEJAMENTO DE ESTUDOS',
                    position: 1
                }]
            };
        case 'MENTORIA':
            return {
                name: 'MENTORIA',
                position: 2,
                sections: [{
                    name: 'MENTORIA',
                    position: 1
                }]
            }

        case 'DISCURSIVA':
            return {
                name: 'PREPARAÇÃO PARA A DISCURSIVA',
                position: 3,
                sections: [{
                    name: 'PREPARAÇÃO PARA A DISCURSIVA',
                    position: 1
                }]
            }
        case 'APROVACAO':
            const sections = []
            for (let i = 0; i < days; i++) {
                sections.push({name: `DIA ${days} ${i + 1}`, position: i + 1});
            }
            return {
                name: 'APROVACAO EM ' + days + ' DIAS',
                position: 4,
                sections
            }
        case 'EVENTOS':
            return {
                name: 'EVENTOS ONLINE',
                position: 5,
                sections: [{
                    name: 'EVENTOS ONLINE',
                    position: 1
                }]
            }
        case 'SIMULADO':
            return {
                name: 'SIMULADOS',
                position: 6,
                sections: [{
                    name: 'SIMULADO 1 DISPONÍVEL EM ',
                    position: 1
                }, {
                    name: 'SIMULADO 2 DISPONÍVEL EM ',
                    position: 1
                }]
            }
        case 'DETONANDO':
            return {
                name: 'DETONANDO A BANCA',
                position: 7
            };
    }
}

export default function CourseRegistrationStepper({}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleType = searchParams.getAll("moduleType") || [];
    const courseId = searchParams.getAll("courseId") || [];
    const days = searchParams.get("days");
    const navigate = useNavigate()

    const steps = ['Dados Básicos'];
    for (const type of moduleType) {
        steps.push(getModuleName(type, days));
    }
    const activeStep = parseInt(searchParams.get('step') || '0');

    const handleNext = () => {
        if (activeStep + 1 === steps.length) {
            navigate('/registration/course');
        }
        setSearchParams({courseId, moduleType, days, step: (activeStep + 1).toString()});
    };

    const handleBack = () => {
        setSearchParams({courseId, moduleType, days, step: (activeStep - 1).toString()});
    };

    const modules = moduleType.map(type => {
        return getModule(type, days)
    })

    console.log(modules[activeStep - 1]);
    return (
        <Box sx={{width: '100%', height: '100%', pt: 1}}>
            <Stepper sx={{width: '100%'}}>
                {steps.map((step, index) => (
                    <Step
                        key={step}
                        indicator={
                            <StepIndicator
                                variant={activeStep <= index ? 'soft' : 'solid'}
                                color={activeStep < index ? 'neutral' : 'primary'}
                            >
                                {activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(activeStep > index &&
                                    index !== 2 && {bgcolor: 'primary.solidBg'}),
                            },
                        }}
                    >
                        <StepButton>{step}</StepButton>
                    </Step>
                ))}
            </Stepper>
            <CourseRegistrationStepperContent activeStep={activeStep}
                                              courses={courseId}
                                              module={modules[activeStep - 1]}
                                              onBack={handleBack}
                                              onNext={handleNext}/>
        </Box>
    )
}
