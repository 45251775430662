import {Autocomplete, CircularProgress} from "@mui/joy";
import SchoolIcon from '@mui/icons-material/School';import FormControl from "@mui/joy/FormControl";
import * as React from "react";
import ErrorAlert from "../ErrorAlert";
import {useLectures} from "../../hooks/useLectures";

export default function LectureSelect({
                                          discipline,
                                          subject,
                                          selectedLectures = [],
                                          onChange,
                                          sort,
                                      }) {
    const [data, loading, error] = useLectures({
        disciplineId: discipline ? [discipline.id] : [],
        subjectId: subject ? [subject.id] : [],
        sort: sort ? sort : 'discipline.name,teacher.name,name',
        size: 1000});

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    const items = data?.content.filter(i => !selectedLectures.includes(i.id)) || [];
    return (

        <FormControl size='sm' sx={{flexGrow: 1}}>
            <Autocomplete getOptionLabel={option => option.name}
                          getOptionKey={option => option.id}
                          groupBy={(option) => option.discipline?.name || 'Sem Disciplina'}
                          options={items}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => onChange(newValue)}
                          limitTags={2}
                          startDecorator={<SchoolIcon />}
                          openOnFocus={true}
                          noOptionsText="Sem opções"
                          loading={loading}
                          loadingText="Carregando..."
                          endDecorator={
                              loading ? (
                                  <CircularProgress size="sm" sx={{bgcolor: 'background.surface'}}/>
                              ) : null
                          }
            />
        </FormControl>
    )
}
