import {useState} from "react";
import axios from "axios";
import {mutate} from "swr";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import LectureSelect from "../../../../../../components/lecture/LectureSelect";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {Alert, LinearProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import SectionLectureMenu from "../../section/SectionLectureMenu";

export default function SelectLecturesStepLectureSectionTable({moduleName, courses, section}) {
    const [lectureSection, setLectureSection] = useState(section.lectures || [])
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const coursesParam = `?courses=${courses.join("&courses=")}`

    const handleAddLecture = (lecture) => {
        if (!lecture) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        axios.patch(`section/${section.name}/module/${moduleName}/lecture/${lecture.id}${coursesParam}`)
             .then(response => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/section/module/${moduleName}`,
                     )).then(() => {
                         const items =
                         setLectureSection([...lectureSection, {
                             id: lecture.id,
                             position: lectureSection.length + 1,
                             name: lecture.name,
                             teacher: lecture.teacher.name,
                         }]);
                     });

             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    const handleRemoveLecture = (lecture) => {
        setSubmiting(true);
        setSubmitError(undefined);
        axios.delete(`section/${section.name}/module/${moduleName}/lecture/${lecture.id}${coursesParam}`)
             .then(response => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/section/module/${moduleName}`))
                     .then(() => setLectureSection(lectureSection.filter(l => l.id !== lecture.id)));
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let backup = JSON.parse(JSON.stringify(lectureSection));
        let tempData = Array.from(lectureSection);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        for (let i = 0; i < tempData.length; i++) {
            tempData[i].position = i + 1;
        }
        setLectureSection(tempData);
        axios.patch(`section/${section.name}/module/${moduleName}/lecture/position${coursesParam}`, tempData.map(ls => ls.id))
             .then(() => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`section/module/${moduleName}`));
             })
             .catch(error => {
                 setLectureSection(backup);
                 setSubmitError(error);
             });
    };

    return (
        <Sheet
            variant="soft"
            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
        >
            <Stack spacing={1}>
                <Typography level="body-lg" component="div">
                    Selecionar Aulas
                </Typography>
                <LectureSelect onChange={handleAddLecture}/>
                {submiting ? <LinearProgress />: undefined}
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                {lectureSection.length > 0 ? <DragDropContext onDragEnd={handleDragEnd}>
                    <Table
                        borderAxis="bothBetween"
                        size="sm"
                        aria-label="purchases"
                        sx={{
                            '& > thead > tr > th:nth-child(n + 4), & > tbody > tr > td:nth-child(n + 4)':
                                {textAlign: 'right'},
                            '--TableCell-paddingX': '0.5rem',
                        }}
                    >
                        <thead>
                        <tr>
                            <th style={{width: 60}}>Posição</th>
                            <th>Nome</th>
                            <th style={{width: '25%'}}>Professor</th>
                            <th style={{width: 60}}></th>
                        </tr>
                        </thead>
                        <Droppable droppableId="droppable-3">
                            {(provider) => (
                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                {lectureSection.map((lectureSection, index) => (
                                    <Draggable key={lectureSection.id} draggableId={lectureSection.id.toString()}
                                               index={index}>
                                        {(provider) => (
                                            <tr key={lectureSection.id}
                                                {...provider.draggableProps}
                                                ref={provider.innerRef}>
                                                <td  {...provider.dragHandleProps}
                                                     scope="row">{lectureSection.position}</td>
                                                <td  {...provider.dragHandleProps}>{lectureSection.name}</td>
                                                <td  {...provider.dragHandleProps}>{lectureSection.teacher}</td>
                                                <td>
                                                    <SectionLectureMenu lecture={lectureSection}
                                                                        onDelete={handleRemoveLecture}/>
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext> : <Alert sx={{width: 1, mx: 'auto'}}>Nenhuma aula adicionada.</Alert>}
            </Stack>
        </Sheet>
    )
}