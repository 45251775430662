import {FormHelperText, Grid, Option, Select} from "@mui/joy";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import DomainSelect from "../../../../components/domain/DomainSelect";
import * as React from "react";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import ErrorAlert from "../../../../components/ErrorAlert";
import Button from "@mui/joy/Button";
import axios from "axios";
import Divider from "@mui/joy/Divider";
import {mutate} from "swr";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/joy/Typography";
import Checkbox from "@mui/joy/Checkbox";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{'header': [1, 2, 3, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false
    }
};

export default function CourseEditForm({course}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, setValue, formState: {errors}} = useForm({mode: 'all'});
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/registration/course');
    }
    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        axios.put(`course/${course.id}`, data)
             .then(response => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/course`))
                     .then(() => handleBack());
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Dados do Curso</Typography>
                </Box>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <Stack spacing={1}>
                    <Controller
                        name="name"
                        defaultValue={course.name}
                        control={control}
                        rules={{required: 'Campo obrigatório'}}
                        helperText={errors.name?.message}
                        render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.name}>
                            <FormLabel>Nome</FormLabel>
                            <Input type="text" {...field}/>
                            <FormHelperText>{errors.name?.message}</FormHelperText>
                        </FormControl>}
                    />
                    <Grid container spacing={1}>
                        <Grid xl={3} lg={4} xs={12}>
                            <DomainSelect control={control}
                                          setValue={setValue}
                                          errors={errors}
                                          defaultValue={course.institution}
                                          domain="institution"
                                          title="Instituição"
                                          mandatory={false}/>
                        </Grid>
                        <Grid xl={3} lg={4} xs={12}>
                            <DomainSelect control={control}
                                          setValue={setValue}
                                          errors={errors}
                                          defaultValue={course.jobTitle}
                                          name={'jobTitle'}
                                          domain="job-title"
                                          title="Cargo"
                                          mandatory={false}/>
                        </Grid>
                        <Grid xl={3} lg={4} xs={12}>
                            <Controller
                                name="type"
                                defaultValue={course.type}
                                control={control}
                                rules={{required: 'Selecione um tipo de curso'}}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <FormControl size="sm" error={!!errors.roleId}>
                                            <FormLabel>Tipo</FormLabel>
                                            <Select placeholder="Selecione um Tipo de Curso"
                                                    onChange={(event, newValue) => onChange(newValue)}
                                                    value={value}>
                                                <Option value="PRE_EDITAL">Pré Edital</Option>
                                                <Option value="POS_EDITAL">Pós Edital</Option>
                                                <Option value="ISOLADAS">Isoladas</Option>
                                                <Option value="DETONANDO">Detonando</Option>
                                            </Select>
                                            <FormHelperText>{errors.roleId?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid lg={6} xs={12}>
                            <Controller
                                name="urlPortal"
                                control={control}
                                defaultValue={course?.urlPortal || ''}
                                rules={{required: 'Campo obrigatório'}}
                                helperText={errors.urlPortal?.message}
                                render={({field}) =>
                                    <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.urlPortal}>
                                        <FormLabel>Url Portal</FormLabel>
                                        <Input type="text" {...field}/>
                                        <FormHelperText>{errors.urlPortal?.message}</FormHelperText>
                                    </FormControl>
                                }
                            />
                        </Grid>
                        <Grid xl={3} lg={4} xs={12}>
                            <Controller
                                name="salary"
                                defaultValue={course?.salary || ''}
                                control={control}
                                helperText={errors.course?.salary?.message}
                                render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                                  error={!!errors.course?.salary}>
                                    <FormLabel>Salário: </FormLabel>
                                    <Input startDecorator="R$" type="number" step={0.01} {...field}
                                    />
                                    <FormHelperText>{errors.salary?.message}</FormHelperText>
                                </FormControl>}
                            />
                        </Grid>
                        <Grid xl={3} lg={4} xs={12}>
                            <Controller
                                name="vacancy"
                                defaultValue={course?.vacancy || ''}
                                control={control}
                                helperText={errors.course?.vacancy?.message}
                                render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                                  error={!!errors.course?.vacancy}>
                                    <FormLabel>Vagas</FormLabel>
                                    <Input type="number" step={1} {...field}/>
                                    <FormHelperText>{errors.course?.vacancy?.message}</FormHelperText>
                                </FormControl>}
                            />
                        </Grid>
                    </Grid>
                    <Grid mt={1} container spacing={1}>
                        <Grid md={3} sm={6} xs={12}>
                            <Controller
                                name="reserve"
                                control={control}
                                defaultValue={course?.reserve || false}
                                render={({field: {onChange, value}}) =>
                                    <Checkbox size="sm"
                                              label="Cadastro Reserva"
                                              onChange={event => onChange(event.target.checked)}
                                              checked={value}/>
                                }/>
                        </Grid>
                        <Grid md={3} sm={6} xs={12}>
                            <Controller
                                name="published"
                                control={control}
                                defaultValue={course.published}
                                render={({field: {onChange, value}}) =>
                                    <Checkbox size="sm"
                                              label="Publicado"
                                              onChange={event => onChange(event.target.checked)}
                                              checked={value}/>
                                }/>
                        </Grid>
                        <Grid xs={12}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={course?.description || ""}
                                render={({field: {onChange, value}}) =>
                                    <FormControl size="sm" error={!!errors.description}>
                                        <FormLabel>Descrição</FormLabel>
                                        <ReactQuill className="comment"
                                                    theme="snow"
                                                    modules={modules}
                                                    onChange={event => onChange(event)}
                                                    value={value}/>
                                        <FormHelperText>{errors.description?.message}</FormHelperText>
                                    </FormControl>
                                }/>
                        </Grid>
                    </Grid>
                </Stack>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button variant="plain" color="neutral" onClick={handleBack}>
                            Voltar
                        </Button>
                        <Button loading={submiting} type="submit" size="sm" variant="solid">
                            Salvar
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    )
}
