import {Alert, CircularProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import * as React from "react";
import Divider from "@mui/joy/Divider";
import {useSearchParams} from "react-router-dom";
import ErrorAlert from "../../../../components/ErrorAlert";
import Paginator from "../../../../components/Paginator";
import {useLectures} from "../../../../hooks/useLectures";
import LectureRow from "./LectureRow";

export default function LectureTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get('name') || '';
    const teacherId = searchParams.getAll("teacherId");
    const subjectId = searchParams.getAll("subjectId");
    const disciplineId = searchParams.getAll("disciplineId");
    const page = searchParams.get('page') || 1;
    const size = searchParams.get('size') || 10;
    const sort = searchParams.get('sort') || 'discipline.name,teacher.name,name';
    const [data, loading, error] = useLectures(
        {
            disciplineId,
            subjectId,
            teacherId,
            name,
            page: page - 1,
            size,
            sort,
        }
    );

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (!data) {
        return null;
    }

    const lectures = data.content || [];
    const totalPages = data.totalPages || 0;

    if (lectures.length === 0) {
        return (
            <Alert sx={{maxWidth: 800, width: 1, mx: 'auto'}}>Nenhum aula encontrada.</Alert>
        )
    }
    return (
        <>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                    display: 'initial',
                    mx: 'auto',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{width: 60, padding: '12px 12px 12px 16px'}}>Id</th>
                        <th style={{width: 240, padding: '12px 16px'}}>Nome</th>
                        <th style={{width: 140, padding: '12px 16px'}}>Professor</th>
                        <th style={{width: 140, padding: '12px 16px'}}>Disciplina</th>
                        <th style={{width: 200, padding: '12px 16px'}}>Assuntos</th>
                        <th style={{width: 140, padding: '12px 16px'}}>Data de Criação</th>
                        <th style={{width: 80, padding: '12px 16px'}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {lectures.map((lecture) => (
                        <LectureRow key={lecture.id} lecture={lecture}/>
                    ))}
                    </tbody>
                </Table>
                <Divider/>
                <Paginator sx={{p: '16px'}}
                           page={page}
                           totalPages={totalPages}
                           onPageChange={page => setSearchParams({page})}/>
            </Sheet>
        </>
    )
}
