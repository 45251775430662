import {DialogActions, DialogContent, FormHelperText, Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {mutate} from "swr";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ErrorAlert from "../../../../../../components/ErrorAlert";

export default function SelectLecturesStepSectionModal({moduleName, section, courses, open, onClose}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, reset, formState: {errors}} = useForm({mode: 'all'});
    const coursesParam = `?courses=${courses.join("&courses=")}`

    if (!moduleName) {
        throw new Error('moduleName is required');
    }

    if (!courses) {
        throw new Error('courses is required');
    }

    const handleClose = () => {
        reset();
        onClose();
    }

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmitting(true);
        setSubmitError(undefined);
        (section ? axios.put(`section/${section.name}/module/${moduleName}${coursesParam}`, data) : axios.post(`section/module/${moduleName}${coursesParam}`, data))
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/section`))
                    .then(handleClose);
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmitting(false));
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{section ? 'Alterar' : 'Adicionar'} Seção</DialogTitle>
                    <DialogContent>
                        <ErrorAlert error={submitError}/>
                        <Stack spacing={2}>
                            <Controller
                                name="position"
                                defaultValue={section?.position || ''}
                                control={control}
                                rules={{required: 'Campo obrigatório'}}
                                helperText={errors.position?.message}
                                render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                                  error={!!errors.position}>
                                    <FormLabel>Posição</FormLabel>
                                    <Input type="number" {...field}/>
                                    <FormHelperText>{errors.position?.message}</FormHelperText>
                                </FormControl>}
                            />
                            <Controller
                                name="name"
                                defaultValue={section?.name || ''}
                                control={control}
                                rules={{required: 'Campo obrigatório'}}
                                helperText={errors.name?.message}
                                render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                                  error={!!errors.name}>
                                    <FormLabel>Nome</FormLabel>
                                    <Input type="text" {...field}/>
                                    <FormHelperText>{errors.name?.message}</FormHelperText>
                                </FormControl>}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <DialogActions>
                            <Button loading={submitting} type="submit" variant="solid">
                                Salvar
                            </Button>
                            <Button variant="plain" color="neutral" type="button" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </DialogActions>
                </form>
            </ModalDialog>
        </Modal>
    )
}
