import {Autocomplete, CircularProgress, FormHelperText} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {useState} from "react";
import {Controller} from "react-hook-form";
import * as React from "react";
import AddDomainButton from "../AddDomainButton";
import SubjectModal from "./SubjectModal";
import ErrorAlert from "../../ErrorAlert";
import {useSubject, useSubjectByDiscipline} from "../../../hooks/useSubject";

const getDefaultValue = (value, multiple) => {
    if (!value) {
        return multiple ? [] : null;
    } else {
        return value;
    }
}

export default function SubjectSelect({
                                          discipline,
                                          control,
                                          setValue,
                                          defaultValue = [],
                                          errors,
                                          sort,
                                          mandatory = true,
                                          canAdd = true,
                                          fullwidth = false
                                      }) {
    const [data, loading, error] = useSubjectByDiscipline({disciplineId: discipline ? [discipline.id] : [], sort});
    const [open, setOpen] = useState(false);

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    const handleClose = (value) => {
        if (value) {
            setValue('subjects', value);
            setOpen(false);
        }
    }

    const items = data?.content || [];

    return (
        <>
            <SubjectModal open={open} onClose={handleClose}/>
            <Stack direction="row">
                <Controller
                    name="subjects"
                    control={control}
                    rules={mandatory ? {required: 'Campo obrigatório'} : {}}
                    defaultValue={getDefaultValue(defaultValue, true)}
                    render={({field: {onChange, value}}) =>
                        <FormControl size='sm' sx={{flexGrow: 1}} error={!!errors.subjects}>
                            <FormLabel>Assuntos</FormLabel>
                            <Autocomplete multiple
                                          disabled={!!!discipline}
                                          getOptionLabel={option => option.name}
                                          getOptionKey={option => option.id}
                                          options={items}
                                          isOptionEqualToValue={(option, value) => option.id === value.id}
                                          onChange={(event, newValue) => onChange(newValue)}
                                          value={value.filter(v => v.discipline?.id === discipline?.id)}
                                          limitTags={2}
                                          noOptionsText="Sem opções"
                                          loading={loading}
                                          loadingText="Carregando..."
                                          endDecorator={
                                              loading ? (
                                                  <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                                              ) : null
                                          }
                            />
                            <FormHelperText>{errors.subjects?.message}</FormHelperText>
                        </FormControl>}/>
                <AddDomainButton show={canAdd} fullwidth={fullwidth} onClick={() => setOpen(true)}/>
            </Stack>
        </>
    )
}
