import {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/joy/IconButton";
import * as React from "react";
import SelectLecturesStepSectionModal from "./SelectLecturesStepSectionModal";
import SectionMenu from "../../section/SectionMenu";
import SelectLecturesStepLectureSectionTable from "./SelectLecturesStepLectureSectionTable";

export default function SelectLecturesStepSectionRow({moduleName, courses, section, initialOpen, provider}) {
    const [open, setOpen] = useState(initialOpen || false);
    const [remove, setRemove] = useState(false);
    const [edit, setEdit] = useState(false);

    if (!moduleName) {
        throw new Error('moduleName is required');
    }

    if (!courses) {
        throw new Error('courses is required');
    }

    return (
        <>
            <SelectLecturesStepSectionModal open={edit} onClose={() => setEdit(false)} moduleName={moduleName} courses={courses} section={section}/>
            <tr key={section.id}
                {...provider.draggableProps}
                ref={provider.innerRef}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <td scope="row" {...provider.dragHandleProps}>{section.position}</td>
                <td {...provider.dragHandleProps}>{section.name}</td>
                <td><SectionMenu onEdit={() => setEdit(true)} onDelete={() => setRemove(true)}/></td>
            </tr>
            <tr>
                <td style={{height: 0, padding: 0}} colSpan={6}>
                    {open && (
                        <SelectLecturesStepLectureSectionTable moduleName={moduleName} courses={courses} section={section} key={section} />
                    )}
                </td>
            </tr>
        </>
    )
}
