import {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/joy/IconButton";
import * as React from "react";
import SectionMenu from "./SectionMenu";
import SectionModal from "./SectionModal";
import LectureSectionTable from "./LectureSectionTable";
import SectionRemoveModal from "./SectionRemoveModal";

export default function SectionRow({courseId, moduleId, section, initialOpen, provider}) {
    const [open, setOpen] = useState(initialOpen || false);
    const [remove, setRemove] = useState(false);
    const [edit, setEdit] = useState(false);

    return (
        <>
            <SectionModal open={edit} onClose={() => setEdit(false)} moduleId={moduleId} section={section}/>
            <SectionRemoveModal open={remove} onClose={() => setRemove(false)} moduleId={moduleId} section={section}/>
            <tr key={section.id}
                {...provider.draggableProps}
                ref={provider.innerRef}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <td scope="row" {...provider.dragHandleProps}>{section.position}</td>
                <td {...provider.dragHandleProps}>{section.name}</td>
                <td><SectionMenu onEdit={() => setEdit(true)} onDelete={() => setRemove(true)}/></td>
            </tr>
            <tr>
                <td style={{height: 0, padding: 0}} colSpan={6}>
                    {open && (
                        <LectureSectionTable courseId={courseId} moduleId={moduleId} sectionId={section.id} lectureSectionDefault={section.lectureSection} />
                    )}
                </td>
            </tr>
        </>)
}
