import {useCourses} from "../../../../../../hooks/useCourses";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, CircularProgress} from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import Box from "@mui/joy/Box";
import * as React from "react";

export default function SelectLectureStepCourseList({courses}) {
    const page = 0;
    const size = 99;
    const sort = 'institutionId.name,jobTitle.name,name';
    const [data, loading, error] = useCourses(
        {
            id: courses,
            page,
            size,
            sort,
        }
    );

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <AccordionGroup>
            <Accordion>
                <AccordionSummary>Cursos</AccordionSummary>
                <AccordionDetails>
                    <List>
                        {data.content.map(course => (
                            <ListItem>
                                {course.name}
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </AccordionGroup>
    )
}