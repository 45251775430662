import './App.css';
import {CssBaseline, CssVarsProvider, extendTheme} from "@mui/joy";
import {Outlet} from "react-router-dom";
import {useEffect, useLayoutEffect} from "react";
import useScript from "./hooks/useScript";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/pt-br';
import ErrorPage from "./components/ErrorPage";
import {ErrorBoundary} from "@sentry/react";
import {useLocation} from 'react-router-dom';
import ReactGA from "react-ga4";

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#e9ebf0',
                    100: '#c7ccdc',
                    200: '#a4acc4',
                    300: '#68729a',
                    400: '#404876',
                    500: '#2d2f50',
                    600: '#2c2e4e',
                    700: '#252641',
                    800: '#1d1f34',
                    900: '#161727',

                },
                //    neutral: {
                // 50: '#eceefd',
                //        100: '#d5d8f6',
                //        200: '#a6adef',
                //        300: '#7680ea',
                //        400: '#4f5ae4',
                //        500: '#3742e2',
                //        600: '#2c36e3',
                //        700: '#2129c9',
                //        800: '#1b24b4',
                //        900: '#0f1e9e',
                //    }
            },
        },
        dark: {
            palette: {
                primary: {
                    50: '#e9ebf0',
                    100: '#c7ccdc',
                    200: '#a4acc4',
                    300: '#828bab',
                    400: '#68729a',
                    500: '#4f5a8a',
                    600: '#495281',
                    700: '#404876',
                    800: '#393f69',
                    900: '#2d2f50'
                },
                //    neutral: {
                // 50: '#eceefd',
                //        100: '#d5d8f6',
                //        200: '#a6adef',
                //        300: '#7680ea',
                //        400: '#4f5ae4',
                //        500: '#3742e2',
                //        600: '#2c36e3',
                //        700: '#2129c9',
                //        800: '#1b24b4',
                //        900: '#0f1e9e',
                //    }
            },
        },
    },
});

const useEnhancedEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export default function App() {
    const status = useScript('https://unpkg.com/feather-icons');
    // useScript('https://player.pandavideo.com.br/api.v2.js');

    useEnhancedEffect(() => {
        // Feather icon setup: https://github.com/feathericons/feather#4-replace
        // @ts-ignore
        if (typeof feather !== 'undefined') {
            // eslint-disable-next-line no-undef
            feather.replace();
        }
    }, [status]);

    ReactGA.initialize('G-LD19VNZ5F7');
    const location = useLocation();
    useEffect(() => {
        // ReactGA.send({ hitType: "pageview", page: location.pathname + location.search , title: location.pathname});
    }, [location]);

    return (
        <CssVarsProvider defaultMode="light" theme={theme} disableTransitionOnChange>
            <CssBaseline/>
            <ErrorBoundary fallback={<ErrorPage/>}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                    <Outlet/>
                </LocalizationProvider>
            </ErrorBoundary>
        </CssVarsProvider>
    );
}
