import SelectLecturesStep from "./select-lectures-step/SelectLecturesStep";
import CourseBasicInfoStep from "./CourseBasicInfoStep";


export default function CourseRegistrationStepperContent({activeStep, courses, module, onBack, onNext}) {

    switch (activeStep) {
        case 0:
            return <CourseBasicInfoStep onNext={onNext}/>;
        default:
            return <SelectLecturesStep courses={courses} module={module} onNext={onNext} onBack={onBack}/>;
    }
}
