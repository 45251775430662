import {FormHelperText, Grid} from "@mui/joy";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import DomainSelect from "../../../../../components/domain/DomainSelect";
import * as React from "react";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import ErrorAlert from "../../../../../components/ErrorAlert";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import {mutate} from "swr";
import ModuleList from "../module/ModuleList";

export default function CourseBasicInfoStep({}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, setValue, formState: {errors}} = useForm({mode: 'all'});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const type = searchParams.get('type') || 'CURSO_LIVRE';

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        data.type = type;
        setSubmiting(true);
        setSubmitError(undefined);
        axios.post(`course`, data)
             .then(response => {
                 console.log(response.data);
                 mutate((key) => typeof key === 'string' && key.startsWith(`/course`))
                     .then(() => {
                         let url;
                         if (response.data.courses.length > 1) {
                             url = `/registration/course/new/?step=1`;
                             url += `&courseId=${response.data.courses.join("&courseId=")}`
                             url += `&moduleType=${response.data.moduleTypes.join("&moduleType=")}`
                         } else {
                             url = `/registration/course/edit/${response.data.courses[0]}`;
                         }
                         return navigate(url);
                     })
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
            <Box sx={{mb: 1}}>
                <Typography
                    level="title-md"> {type === 'PRE_EDITAL' || type === 'POS_EDITAL' ? 'Dados do(s) curso(s)' : 'Dados do curso'} </Typography>
                {type === 'PRE_EDITAL' || type === 'POS_EDITAL' ? <Typography level="body-sm">
                    Selecione mais de um cargo para gerar o curso em lote
                </Typography> : undefined}
            </Box>
            <Divider/>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <Grid container spacing={1}>
                {type === 'CURSO_LIVRE' ? <Grid xl={9} lg={8} sm={6} xs={12}>
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: 'Campo obrigatório'}}
                        helperText={errors.name?.message}
                        render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.name}>
                            <FormLabel>Nome</FormLabel>
                            <Input type="text" {...field}/>
                            <FormHelperText>{errors.name?.message}</FormHelperText>
                        </FormControl>}
                    />

                </Grid> : undefined}
                {type !== 'CURSO_LIVRE' ?
                    <Grid xl={3} lg={4} sm={6} xs={12}>
                        <DomainSelect control={control}
                                      setValue={setValue}
                                      errors={errors}
                                      domain="institution"
                                      title="Instituição"/>
                    </Grid>
                    : undefined}
                {type !== 'CURSO_LIVRE' ?
                    <Grid xl={3} lg={4} sm={6} xs={12}>
                        <DomainSelect control={control}
                                      setValue={setValue}
                                      errors={errors}
                                      name={'jobTitles'}
                                      domain="job-title"
                                      title="Cargos"
                                      multiple={true}/>
                    </Grid>
                    : undefined}
                <Grid xl={3} lg={4} sm={6} xs={12}>
                    <Stack direction="row">
                        <Controller
                            name="days"
                            control={control}
                            helperText={errors.days?.message}
                            render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.days}>
                                <FormLabel>Aprovação em: </FormLabel>
                                <Input type="number" step={1} {...field}
                                       endDecorator={<Typography level="body-sm">dias</Typography>}
                                />
                                <FormHelperText>{errors.days?.message}</FormHelperText>
                            </FormControl>}
                        />
                        <Box sx={{height: 34, width: 34, mt: '24px', ml: '8px'}}>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <ModuleList control={control}/>
            <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                    <Button size="sm" variant="soft" onClick={() => navigate('/registration/course')}>
                        Voltar
                    </Button>
                    <Button loading={submiting} type="submit" size="sm" variant="solid">
                        Próximo
                    </Button>
                </CardActions>
            </CardOverflow>
        </Card>
    </form>)
}
