import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import axios from "axios";
import {mutate} from "swr";
import ErrorAlert from "../../../../../components/ErrorAlert";
import Button from "@mui/joy/Button";
import {styled} from "@mui/joy";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function DocumentForm({lecture, document, onClose}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [file, setFile] = useState(null);

    const {handleSubmit, control, formState: {errors}} = useForm({mode: 'all'});

    if (!lecture) {
        throw new Error("lecture cannot be null");
    }
    const handleSubmitWithoutPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
    };

    const endpoint = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('file', file);

        if (document) {
            if (file) {
            return axios.put(`lecture/${lecture.id}/document/${document.id}`,
                formData, {headers: { "Content-Type": "multipart/form-data" }});
            }
            return  axios.patch(`lecture/${lecture.id}/document/${document.id}/name`, data);
        } else {
            return axios.post(`lecture/${lecture.id}/document`,
                formData, {headers: { "Content-Type": "multipart/form-data" }
                })
        }

    }

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        endpoint(data)
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/lecture/${lecture.id}`))
                    .then(() => onClose(response.data));
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    }

    const handleChange = (event) => {
      setFile(event.target.files[0]);
    }

    return (
        <>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <form onSubmit={handleSubmitWithoutPropagation}>
                <Stack gap={2}>
                    <Controller
                        name="name"
                        defaultValue={document?.name || ''}
                        control={control}
                        rules={{required: 'Digite o nome do documento'}}
                        helperText={errors.name?.message}
                        render={({field}) =>
                            <FormControl size="sm" sx={{flexGrow: 1}}>
                                <FormLabel>Nome</FormLabel>
                                <Input{...field}/>
                            </FormControl>
                        }
                    />
                    <Stack sx={{height: "100%"}}>
                        <Button
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            variant="outlined"
                            color="primary"
                            startDecorator={<CloudUploadIcon />}
                        >
                            Selecionar Arquivo
                            <VisuallyHiddenInput type="file" accept="application/pdf" onChange={handleChange} />
                        </Button>
                        {file?.name}
                    </Stack>
                    <Button loading={submiting} type="submit">Salvar</Button>
                </Stack>
            </form>
        </>
    )
}
