import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import {Alert, CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import {useState} from "react";
import {useCoursesSections} from "../../../../../../hooks/useCoursesSections";
import SelectLecturesStepSectionModal from "./SelectLecturesStepSectionModal";
import SelectLecturesStepSectionTableContent from "./SelectLecturesStepSectionTableContent";

export default function SelectLecturesStepSectionTable({courses, moduleName}) {
    if (!courses) {
        throw new Error("courses is required");
    }
    if (!moduleName) {
        throw new Error("moduleName is required");
    }
    const [open, setOpen] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [data, loading, error] = useCoursesSections({
        courses,
        moduleName,
    });

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (!data) {
        return null;
    }

    const sections = data;

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                <Typography level="title-md">Seções</Typography>
                <Button size="sm"
                        type="button"
                        sx={{mt: 1}}
                        startDecorator={<AddIcon/>}
                        onClick={() => setOpen(true)}
                >
                    Adicionar
                </Button>
                <SelectLecturesStepSectionModal open={open} onClose={() => setOpen(false)} moduleName={moduleName}
                                                courses={courses}/>
            </Box>
            {sections.length > 0 ?
                <Sheet
                    variant="outlined"
                    sx={{
                        // display: {xs: 'none', sm: 'initial'},
                        width: '100%',
                        borderRadius: 'sm',
                        flexShrink: 1,
                        overflow: 'auto',
                        minHeight: 0,
                    }}
                >
                    <SelectLecturesStepSectionTableContent moduleName={moduleName}
                                                           courses={courses}
                                                           defaultSections={sections}
                                                           onError={(error) => setSubmitError(error)}
                                                           key={sections}/>
                </Sheet> : <Alert sx={{width: 1, mx: 'auto'}}>Nenhuma seção adicionada.</Alert>}
        </>
    )
}
