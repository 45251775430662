import {Autocomplete, CircularProgress} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {Controller} from "react-hook-form";
import * as React from "react";
import {useSubject, useSubjectByDiscipline} from "../../../hooks/useSubject";
import ErrorAlert from "../../../components/ErrorAlert";

export default function SubjectAutocomplete({
                                          disciplines = [],
                                          control,
                                          defaultValue = [],
                                          sort = 'discipline.name,name'
                                      }) {
    const disciplineId = disciplines.map(d => d.id);
    const [data, loading, error] = useSubjectByDiscipline({disciplineId, sort});

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    const items = data?.content || [];

    return (
        <Controller
            name="subjectId"
            control={control}
            defaultValue={items.filter(i => defaultValue.includes(i.id.toString()))}
            render={({field: {onChange, value}}) =>
                <FormControl size='sm' sx={{flexGrow: 1}}>
                    <FormLabel>Assuntos</FormLabel>
                    <Autocomplete multiple
                                  groupBy={(option) => option.discipline.name}
                                  getOptionKey={option => option.id}
                                  disabled={disciplines.length === 0}
                                  getOptionLabel={option => option.name}
                                  options={items}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(event, newValue) => onChange(newValue)}
                                  value={value.filter(v => disciplineId.includes(v.discipline?.id))}
                                  limitTags={2}
                                  noOptionsText="Sem opções"
                                  loading={loading}
                                  loadingText="Carregando..."
                                  endDecorator={
                                      loading ? (
                                          <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                                      ) : null
                                  }
                    />
                </FormControl>}/>


    )
}
