import {DialogActions, DialogContent, FormHelperText, Grid, Modal, ModalDialog, Option, Select} from "@mui/joy";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import DomainSelect from "../../../../components/domain/DomainSelect";
import * as React from "react";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import ErrorAlert from "../../../../components/ErrorAlert";
import Button from "@mui/joy/Button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/joy/Typography";
import {mutate} from "swr";
import ModuleList from "./module/ModuleList";
import DialogTitle from "@mui/joy/DialogTitle";
import Checkbox from "@mui/joy/Checkbox";

const getTypeName = (type) => {
    switch (type) {
        case 'PRE_EDITAL':
            return 'Pré Edital';
        case 'POS_EDITAL':
            return 'Pós Edital';
        case 'ISOLADAS':
            return 'Isoladas';
        case 'DETONANDO':
            return 'Detonando';
        default:
            return '';
    }
}

export default function CourseCreateModal({open, onClose, course}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, watch, setValue, reset, formState: {errors}} = useForm({mode: 'all'});
    const navigate = useNavigate();

    const handleClose = () => {
        reset();
        onClose();
    }
    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        data.course.institution = data.institution;
        data.course.jobTitle = data.jobTitle;
        data.duplicateFromCourseId = course?.id;
        setSubmitting(true);
        setSubmitError(undefined);
        axios.post(`course`, data)
             .then(response => {
                 console.log(response.data);
                 mutate((key) => typeof key === 'string' && key.startsWith(`/course`))
                     .then(() => {
                         onClose();
                         return navigate(`/registration/course/edit/${response.data.id}`);
                     })
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmitting(false));
    }

    const watchFields = watch(['course.type', 'institution', 'jobTitle', 'initialModules.APROVACAO']);
    const type = watchFields[0] || course?.type;
    const institution = watchFields[1] || course?.institution;
    const jobTitle = watchFields[2];
    const aprovacao = watchFields[3] === undefined ? true : watchFields[3];

    if (type && (type === 'PRE_EDITAL' || type === 'POS_EDITAL')) {
        setValue('course.name', `${institution?.name || ''} - ${jobTitle?.name || ''} - (${getTypeName(type)})`);
    } else {
        setValue('course.name', course?.name || '');
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Adicionar Curso</DialogTitle>
                    <DialogContent>
                        <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                        <Stack spacing={1}>
                            <Controller
                                name="course.type"
                                defaultValue={course?.type}
                                control={control}
                                rules={{required: 'Campo obrigatório'}}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <FormControl size="sm" error={!!errors.course?.type}>
                                            <FormLabel>Tipo</FormLabel>
                                            <Select onChange={(event, newValue) => onChange(newValue)}
                                                    value={value}>
                                                <Option value="PRE_EDITAL">Pré Edital</Option>
                                                <Option value="POS_EDITAL">Pós Edital</Option>
                                                <Option value="ISOLADAS">Isoladas</Option>
                                                <Option value="DETONANDO">Detonando</Option>
                                            </Select>
                                            <FormHelperText>{errors.course?.type?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <DomainSelect control={control}
                                          defaultValue={course?.institution}
                                          setValue={setValue}
                                          mandatory={type === 'PRE_EDITAL' || type === 'POS_EDITAL'}
                                          errors={errors}
                                          domain="institution"
                                          title="Instituição"/>
                            <DomainSelect control={control}
                                          defaultValue={course?.jobTitle}
                                          setValue={setValue}
                                          mandatory={false}
                                          errors={errors}
                                          name="jobTitle"
                                          domain="job-title"
                                          title="Cargo"/>
                            {aprovacao ?
                                <Controller
                                    name="course.days"
                                    defaultValue={course?.days || ''}
                                    control={control}
                                    rules={{required: 'Campo obrigatório'}}
                                    helperText={errors.course?.days?.message}
                                    render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                                      error={!!errors.course?.days}>
                                        <FormLabel>Aprovação em: </FormLabel>
                                        <Input disabled={!!course} type="number" step={1} {...field}
                                               endDecorator={<Typography level="body-sm">dias</Typography>}
                                        />
                                        <FormHelperText>{errors.course?.days?.message}</FormHelperText>
                                    </FormControl>}
                                /> : undefined}
                            <Controller
                                name="course.name"
                                control={control}
                                defaultValue={course?.name || ''}
                                rules={{required: 'Campo obrigatório'}}
                                helperText={errors.course?.name?.message}
                                render={({field}) =>
                                    <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.course?.name}>
                                        <FormLabel>Nome</FormLabel>
                                        <Input type="text" {...field}/>
                                        <FormHelperText>{errors.course?.name?.message}</FormHelperText>
                                    </FormControl>
                                }
                            />
                            {course ? undefined : <ModuleList control={control}/>}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <DialogActions>
                            <Button loading={submitting} type="submit" variant="solid">
                                Salvar
                            </Button>
                            <Button variant="plain" color="neutral" type="button" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </DialogActions>
                </form>
            </ModalDialog>
        </Modal>
    )
}
