import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useSubject({disciplineId = [], name= '', sort = 'name', size = 1000, page = 0}) {

    let url = `/subject?page=${page}&sort=${sort}&size=${size}&name=${name}`;
    if (disciplineId) {
        url += `&disciplineId=${disciplineId.join("&disciplineId=")}`;
    }
    const {data, error, isLoading} = useSWR(url, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}


export function useSubjectByDiscipline({disciplineId = [], name= '', sort = 'name', size = 1000, page = 0}) {

    let url = `/subject?page=${page}&sort=${sort}&size=${size}&name=${name}`;
    if (disciplineId) {
        url += `&disciplineId=${disciplineId.join("&disciplineId=")}`;
    }
    const {data, error, isLoading} = useSWR(disciplineId ? url : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}
