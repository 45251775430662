import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import * as React from "react";
import {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Card from "@mui/joy/Card";
import {useNavigate} from "react-router-dom";
import DocumentTable from "./DocumentTable";
import DocumentEditModal from "./DocumentEditModal";


export default function LectureDocumentStep({courseId, moduleId, sectionId, lecture, onBack}) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    if (!lecture) {
        return null;
    }

    const handleFinalize = () => {
        if(courseId && moduleId && sectionId) {
            navigate(`/registration/course/edit/${courseId}/module/${moduleId}?sectionId=${sectionId}`)
        } else {
            navigate("/registration/lecture")
        }
    }

    return (
        <>
            <DocumentEditModal lecture={lecture} open={open} onClose={() => setOpen(false)}/>
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography level="title-md">Documentos</Typography>
                    <Button size="sm"
                            sx={{mt: 1}}
                            startDecorator={<AddIcon/>}
                            onClick={() => setOpen(true)}>
                        Adicionar
                    </Button>
                </Box>
                <Divider/>
                <DocumentTable lecture={lecture}/>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button size="sm" variant="soft" onClick={onBack}>
                            Voltar
                        </Button>
                        <Button onClick={handleFinalize} size="sm" variant="solid">
                            Finalizar
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </>
    )
}
