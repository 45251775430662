import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useSections({
                                moduleId
                            }) {
    const {
        data,
        error,
        isLoading
    } = useSWR(moduleId ?`/module/${moduleId}/section` : undefined, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}

