import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import ErrorAlert from "../../../../../../components/ErrorAlert";
import * as React from "react";
import Card from "@mui/joy/Card";
import {useState} from "react";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CardContent} from "@mui/joy";
import SelectLecturesStepSectionTable from "./SelectLecturesStepSectionTable";
import SelectLectureStepCourseList from "./SelectLectureStepCourseList";

export default function SelectLecturesStep({courses, module, onNext, onBack}) {
    const [submitError, setSubmitError] = useState(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    if (!module) {
        return undefined;
    }

    return (
        <form >
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Selecionar aulas</Typography>
                    <Typography level="body-md">{module.name}</Typography>
                    <SelectLectureStepCourseList courses={courses} />
                </Box>
                <Divider/>
                <CardContent>
                    <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                    <SelectLecturesStepSectionTable courses={courses} moduleName={module.name} onBack={onBack} onNext={onNext}/>
                </CardContent>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button size="sm" variant="soft" onClick={onBack}>
                            Voltar
                        </Button>
                        <Button onClick={onNext} size="sm" variant="solid">
                            Próximo
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    )
}
