import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useCoursesSections({
                                courses, moduleName, page = 0,
                                size = 1000,
                            }) {
    const coursesParam = `?courses=${courses.join("&courses=")}`

    const {
        data,
        error,
        isLoading
    } = useSWR(`/section/module/${moduleName}${coursesParam}`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}

